@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('~assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('~assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('~assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('~assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('~assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('~assets/fonts/Roboto/Roboto-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('~assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('~assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('~assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('~assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('~assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}
