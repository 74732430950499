$white: #fff;

$black: #000;
$black100: #4f4f4f;
$black200: #333;
$black300: #241f21;
$black400: #2d2a2c;
$black500: #434041;

$green100: #6fcf97;
$green200: #86cd9b;
$green300: #6ece95;

$grey100: #eee;
$grey200: #bdbdbd;
$grey300: #999;
$grey400: #f1f1f1;
$grey500: #666;
$grey600: #ccc;
$grey700: #828282;
$grey800: #8c898a;
$grey900: #595758;
$grey1000: #c3c2c3;
$grey1100: #fbf9ff;

$blue100: #a3c7ed;
$blue200: #2d9cdb;

$purple100: #5b44fd;
$purple200: #6d64ff;
$purpleNew: #6d64ff;

$orange100: #352725;
$orange400: #ee7465;
$orange500: #f56e57;

$primary: #001684;
$secondary: #87ebd0;

$red100: #f46e57;

$yellow500: #f5b81c;

// colors for redesign
$midnight50: #5b70ff;
$midnight100: #1d1d30;
$white100: #f7f8ff;
$grey1200: #e8e8ff;
$grey1300: #6f6f6f;
$grey1400: #727d9b;

// box-shadow
$grey-box-shadow:
  0px 4px 8px rgba(0, 0, 0, 0.05),
  0px -4px 8px rgba(0, 0, 0, 0.05);
$midnight-box-shadow: 0 3px 14px 3px;

// rgba
$black-opacity0: rgba(0, 0, 0, 0);
$black-opacity1: rgba(0, 0, 0, 1);
$black-opacity: rgba(0, 0, 0, 0.35);
$black-opacity-light: rgba(0, 0, 0, 0.1);

// hover
$hover-primary: #4f7cb7;
$hover-green100: #8cd9ac;

$bg-main: $grey1100;
$text-default: $black500;

$error: #f44336;

:export {
  white: $white;
  white100: $white100;
  black: $black;
  black100: $black100;
  black200: $black200;
  black300: $black300;
  black400: $black400;
  black500: $black500;
  blackOpacity: $black-opacity;
  blackOpacity0: $black-opacity0;
  blackOpacity1: $black-opacity1;
  blue100: $blue100;
  blue200: $blue200;
  purple100: $purple100;
  purple200: $purple200;
  green100: $green100;
  green200: $green200;
  green300: $green300;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey800: $grey800;
  grey900: $grey900;
  grey1000: $grey1000;
  grey1100: $grey1100;
  grey1200: $grey1200;
  grey1300: $grey1300;
  grey1400: $grey1400;
  greyBoxShadow: $grey-box-shadow;
  midnightBoxShadow: $midnight-box-shadow;
  hoverGreen100: $hover-green100;
  hoverPrimary: $hover-primary;
  orange100: $orange100;
  orange500: $orange500;
  primary: $primary;
  red100: $red100;
  secondary: $secondary;
  bgMain: $bg-main;
  textDefault: $text-default;
  midnight50: $midnight50;
  midnight100: $midnight100;
}
