$font-muli: 'Muli', sans-serif;
$font-mulish: 'Mulish', sans-serif;
$font-baloo-bhai: 'Baloo Bhai 2';
$font-helvetica-neue: 'Helvetica Neue', 'sans-serif';

$gutter: 24px;

$spacing: 32px;

$header-height-small: 50px;
$header-height-large: 60px;

$fab-height-large: 56px;
$fab-height-small: 48px;
$bottom-fab-height: 20px;

$spacing-bottom-list: 100px;
$spacing-bottom-list-sm: 25px;

$spacing-bottom-text: 6px;
$spacing-bottom-text-sm: 2px;
$spacing-bottom-text-lg: 12px;
$spacing-bottom-text-xl: 24px;

$col-width: calc(min(370px, 100%));
$col-width-sm: calc(min(320px, 100%));

:export {
  fabHeightSmall: $bottom-fab-height;
  fabHeightLarge: $fab-height-large;
  headerHeightSmall: $header-height-small;
  headerHeightLarge: $header-height-large;
  fontBalooBhai: $font-baloo-bhai;
}
